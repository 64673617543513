import React from "react";
import cn from "./utils/cn";
export function Root({ children, className, ...rest }) {
  return (
    <div
      {...rest}
      className={cn(
        "aspect-auto   hover:shadow-sm duration-300 p-8 border border-gray-100 rounded-3xl bg-white dark:bg-gray-800 dark:border-gray-700 shadow-2xl shadow-gray-600/10 dark:shadow-none",
        className
      )}
    >
      {children}
    </div>
  );
}
export function Avatar({ url }) {
  return (
    <img
      className="w-12 h-12 rounded-full bg-contain"
      src={url}
      alt="user avatar"
      width="300"
      height="400"
      loading="lazy"
    />
  );
}
export function Header({ className, children }) {
  return (
    <div>
      <h6
        className={`text-lg font-medium text-gray-700 dark:text-white ${className}`}
      >
        {children}
      </h6>
    </div>
  );
}
export function Description({ children, className }) {
  return <p className={cn("mt-8", className)}>{children}</p>;
}
